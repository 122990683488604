<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />

      <h2 class="brand-text text-primary ml-1">
        Daisy
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          <b-spinner label="Chargement..." /> Préparation 🛠
        </h2>
        <p class="mb-3">
          Veuillez patienter, nous chargeons vos données
        </p>

        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="login callback page"
        />

        <div class="mt-2 pt-2">
          <b-button variant="primary" @click="goToHome()">Ca tourne en boucle</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BSpinner, BButton
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import useUserDroits from '@/auth/useUserDroits'

export default {
  components: {
    BLink,
    BImg,
    BSpinner,
    VuexyLogo,
    BButton
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  methods: {
    goToHome() {
        this.$router.push({ name: 'presentation' })
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  async created() {
  
    await this.$authService
      .loginCallback()
      .then(async user => {
        
        this.$store.dispatch('user/setMe', {
          displayName: user.profile.given_name,
          userName: user.profile.name,
          email: user.profile.email,
          uid: user.profile.sub
        })

        this.$store.dispatch('user/fetchPreferencesUtilisateur')
        
        const { fetchDroits } = useUserDroits()
        fetchDroits()

        this.$store.dispatch('user/fetchPreferencesUtilisateur').then((response) => {
          var theme = store.state.user.user_preferences.find((value) => value.key == 'theme');
          if (!theme) {
            theme = "light";
            store.commit('appConfig/UPDATE_SKIN', theme);
            this.$store.dispatch('user/saveUserPreference', {
              key: "theme",
              value: "light",
            }).then((value) => {
              console.log("theme preference update");
            })
          } else {
            store.commit('appConfig/UPDATE_SKIN', theme);
          }
        });


        this.$router.push({ name: 'home' })
      })
      .catch(err => {
        console.log('fail', err)
        console.log({err})
        if(err.message.indexOf('No matching state found in storage') >= 0)
          this.$authService.login()

        this.$http.ressifnet.utils.Logger(err.stack, 'error')
      })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
